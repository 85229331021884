import MuiRadio from "@mui/material/Radio";
import PropTypes from "prop-types";
import FormControlLabel from "../FormControlLabel";

// 하기 처럼 사용 필요
// import RadioGroup from "@mui/material/RadioGroup";
/* 
<RadioGroup
  name="test"
  defaultValue="2"
  onChange={event => {
    console.log(event.target.value);
  }}
  sx={{ gap: "8px" }}
></RadioGroup>;
*/
const Radio = ({ value, label, defaultChecked = false, disabled = false, sx, ...props }) => {
  // 디자인 정의에는 사이즈가 없어서, 우선 xsmall로 고정함. 추후 필요 시 size 변경 가능하게 적용 예정
  return (
    <FormControlLabel
      control={
        <MuiRadio
          defaultChecked={defaultChecked}
          size="xsmall"
          sx={{
            padding: "2px 0px",
            color: "primary700.main",
            "&.Mui-disabled": {
              color: "primary500.main",
            },
          }}
          {...{ value, disabled, ...props }}
        />
      }
      {...{ label }}
      sx={{
        "span": {
          fontWeight: "bold",
        },
        ...sx,
      }}
    />
  );
};

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.any,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Radio;
