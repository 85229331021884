import PropTypes from "prop-types";
import styled, { css } from "styled-components";

export const sizeProps = ["small", "medium"];
export const colorProps = ["gradation", "red"];

const sizes = {
  small: {
    text: css`
      align-items: flex-end;
      padding: 8px 14px;
      min-height: 16px;

      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
    `,
    button: css`
      gap: 2px;
      line-height: 12px;
    `,
  },
  medium: {
    text: css`
      padding: 12px 14px;
      min-height: 24px;

      font-size: 13px;
      font-weight: 600;
    `,
    button: css`
      gap: 4px;
      line-height: 16px;
    `,
  },
};

const colors = {
  gradation: css`
    background: linear-gradient(90deg, #5336ff -0.29%, #ff528d 100%);
    color: #fff;
  `,
  red: css`
    border: 1px solid #ffa4aa;
    background: #fdd;
    color: #f82d3e;
  `,
};

const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  padding: 0;
  border-radius: 6px;

  > * {
    width: 100%;
  }
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  ${({ color }) => colors[color]}
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  font-style: normal;
  ${({ size }) => sizes[size].text}
`;

const RightButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  ${({ size }) => sizes[size].button}
`;

const Banner = ({ onClick, children, rightButton, style, ...props }) => {
  return (
    <BannerWrapper onClick={onClick} {...{ style, ...props }}>
      <TextWrapper {...props}>
        {children}
        {rightButton && <RightButton {...props}>{rightButton}</RightButton>}
      </TextWrapper>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  size: PropTypes.oneOf(sizeProps),
  color: PropTypes.oneOf(colorProps),
};

Banner.defaultProps = {
  size: "medium",
  color: "gradation",
};

export default Banner;
