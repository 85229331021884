import { Button, Link, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Symbol from "../Icons/Symbol";
import Modal from "../Modal";
import { Buttons, Content, Title, Wrapper } from "./styled";

const AlertCustomModal = ({ options, close }) => {
  const { title, text, icon, link, button, width, mode } = options;

  const buttonConfigs = {
    danger: [
      {
        color: "danger",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
      {
        color: "secondary",
        variant: "outlined",
        text: button?.cancel?.text || "취소",
        onClick: close,
      },
    ],
    default: [
      {
        color: "secondary",
        variant: "outlined",
        text: button?.cancel?.text || "취소",
        onClick: close,
      },
      {
        color: "primary",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
    ],
    check: [
      {
        color: "primary",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
    ],
  };

  const renderButtons = () => (
    <Buttons direction="horizontal">
      {buttonConfigs[mode]?.map(({ color, variant, text, onClick }, index) => (
        <Button key={index} color={color} variant={variant} size="large" onClick={onClick}>
          {text}
        </Button>
      ))}
    </Buttons>
  );

  return (
    <Modal width={width ?? 400} hideCloseButton>
      <Wrapper>
        <Content>
          {icon ? (
            <Symbol
              name={icon?.name || icon || "warning"}
              variant="contained"
              color={icon?.color || "grey500"}
              fontSize="large"
            />
          ) : null}
          <Title>{title}</Title>
          {text ? (
            <Typography variant="body1" color="text.grey600">
              {text}
            </Typography>
          ) : null}
          {link ? (
            <StyledLink href={link?.url} target="_blank" rel="noopener" underline="hover">
              {link?.title}
            </StyledLink>
          ) : null}
        </Content>
        {renderButtons()}
      </Wrapper>
    </Modal>
  );
};

const StyledLink = styled(Link)`
  font-size: 13px !important;
  && {
    text-decoration: underline !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }
`;

export default AlertCustomModal;
